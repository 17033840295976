import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static values = { id: String, uri: String };

  connect() {
    StimulusReflex.register(this);
    this.render();
  }

  render() {
    if (this.isActionCableConnectionOpen()) {
      try {
        this.stimulate("Parchment::SignatureReflex#render", {
          destination: this.idValue,
          signature_uri: this.uriValue,
        });
      } catch (e) {
        setTimeout(
          function () {
            this.render();
          }.bind(this),
          500
        );
      }
    } else {
      setTimeout(
        function () {
          this.render();
        }.bind(this),
        500
      );
    }
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  toggle(event) {
    const element = event.currentTarget;
    const selector = element.dataset.selector;
    const targetElements = document.querySelectorAll(selector);

    if (targetElements.length) {
      targetElements.forEach((element) => {
        element.classList.toggle("hidden");
      });
    }
  }

  toggleAndReset(event) {
    const element = event.currentTarget;
    const selector = element.dataset.selector;
    const targetElements = document.querySelectorAll(selector);

    if (targetElements.length) {
      targetElements.forEach((target_element) => {
        target_element.classList.toggle("hidden");
        if (element.checked) {
          target_element.setAttribute("required", "required");
          target_element.setAttribute(
            "value",
            target_element.dataset["previousValue"]
          );
        } else {
          target_element.removeAttribute("required");
          target_element.setAttribute("value", "");
        }
      });
    }
  }
}
